
//Timezone browser detection library
import jstz from 'jstz'

// NOTE: SETTING PARAMS IN COOKIE
function setCookie(name, value) {
  // NAME = 'TIMEZONE', VALUE = STRING OF TIMEZONE, EXPIRES = 1 HOUR FROM NOW IN 24HR TIME FORMAT
  const expires = new Date()
  expires.setTime(expires.getTime() + 3600000) // 1 hour in milliseconds
  let cookieValue = `${name}=${encodeURIComponent(value)};expires=${expires.toUTCString()};path=/;`

  if (window.location.protocol === 'https:') {
    cookieValue += 'Secure;'
  }

  document.cookie = cookieValue
}

const timezone = jstz.determine()

// NOTE: this creates the cookie and allows rails app to have access to it
setCookie('timezone', timezone.name())
